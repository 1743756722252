.cardLeyenda{
    -webkit-box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
    -moz-box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
    box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
    background-color: rgba(3, 85, 146, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    text-align: center;
}

.tit{
    margin-top: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 45px;
}

.divTit{
    text-align: center;
    color: white;
}


 
.parrafo{
    font-size: 20px;
     
}


@media screen and (max-width:428px) {
    .tit{
        font-size:22px ;

    }
    .parrafo{
        font-size: 12px;
    }
    
}

@media screen and (max-width: 576px){
    .tit{
        font-size: 30px;
    }
    .parrafo{
        font-size: 15px;
    }
}
