@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  margin-top: 2rem;
}

.borde-footer {
  border-right: 1px solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btnFiltro{
  line-height: 1.1;
}


.tituloDetalle{
  font-family: 'Bebas Neue', cursive;
}
.ss{
  background-color: grey !important;
  border-radius: 100%;
  padding: 15px !important;
}
.closeDetail{
  border-radius: 100%;
  padding: 6px 15px;
  background-color: rgb(255, 38, 38);
  transition: 0.2s;
  border: 1px solid grey;
  box-shadow: 1px 1px 10px grey;
}
.closeDetail:hover{
  color:white;
  background-color: rgb(255,0,0);
  box-shadow: 1px 1px 20px grey;
}
/* .containerImgDetail{
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.xsScreen{
  display: none;
  visibility: hidden;
}
.boton{
  width: auto;
  height: auto;
}
.paginado{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
}
.paginadoButton{
  font-size: 12px;
  border-radius: 40% !important;
}
.paginadoButton[disabled]{
  background-color: lightgray;
  cursor: no-drop;
}
.paginadoInput{
  min-width: 40px;
  max-width: 70px;
  text-align: center;
  font-size: 20px;
  background-color: lightgrey;
}
.paginadoTexto{
  text-align: center;
  min-width: 40px;
  line-height:37px;
  font-size: 20px;
  margin: 0px 4px 0px 4px;
}
.colorTitle{
  width: 100%;
  color:#035592;
  text-align:start;
}

.dialogContainer{
  width: 80% !important;
}

.dialogImage1{
  height: 100% !important;
}
.fecha{
  width: 100% !important;
  margin-bottom: 8px !important;
  padding: 8px !important;
  background-color: rgba(162,159,159,0.2);
}
.MuiInput-underline::before{
  border-bottom: none !important;
  transition: none !important;
}
.MuiInput-underline::after{
  border-bottom: none !important;
  transition: none !important;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

.butonDetail{
  padding: 1px 7px 1px 7px !important;
  font-size: 1rem!important;
  margin-top: 0px;
}
.show-menu {
  display: inherit !important;
}

.hide-menu {
  display: none !important;
}

.cursor-point{
  cursor: pointer;
}

.busqueda-avanzada .accordion-item {
  border: none;
  border-radius: 0;
}

.busqueda-avanzada .accordion-item:first-of-type .accordion-button {
  border-radius: 0;
  border: none;
}

.busqueda-avanzada .accordion-button:not(.collapsed) {
  border: none;
  border-radius: 0;
  color: #035592;
}

.busqueda-avanzada .accordion-button:focus {
  box-shadow: none;
}

.boton-busqueda-avanzada{
  font-size: 1.5rem;
  padding: 1rem 0.25rem;
}

.footer-fondo{
  background-color:rgb(3, 85, 146);
}


@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@media screen and (max-width:1500px){
  .borde-footer {
    border-right: none;
  }
}

.MuiDialogContent-root {
  width: 100% !important;
  padding: 0 !important;
  align-items: center !important;
  border-radius: 0;
  padding: 0px;
}

@media screen and (min-width:1000px) and (max-height:700px){
  .borde-footer {
    border-right: 1px solid;
  }
}

@media screen and (max-width:765px){
  .boxx{
    height: 100% !important;
  }
  .zoom-out-button{
    left: 40% !important;
  }
  .xsScreen{
    display: flex;
    visibility: visible;
  }
  .lgScreen{
    display: none;
  }
  .xsFullScreen{
    width: 100% !important;
  }
  .xsTextAlign{
    text-align: center;
  }
  .xsFontSize{
    font-size: 20px;
  }
  .xssFullScreenText{
    width: 90% !important;
    font-size: 15px;
    padding-left: 20px;
  }
  .xsLineaDivisoria{
    width: 30% !important;
  }
  .xsImage{
    height: 80% !important;
  }

  .xsMarginTop{
    margin-top: 4rem;
  }
  .xsMinTop{
    margin-top: 10px;
  }
  .containerForm{
    height: 450px !important;
  }
  .containerForm2{
    height: 320px !important;
  }
  .containerResultados{
    margin-top: 5rem !important;
  }
  .desde{
    width: 82%;
  }
  .borde-footer {
    border-right: none;
  }
}

@media screen and (max-width:600px){
  .xssFullScreen{
    width: 68% !important;
  }
  .xssMarginTop{
    top: 65vh !important;
  }
  .centerContainer{
    justify-content: center;
  }

  .xssFullScreenText{
    width: 90% !important;
    font-size: 15px;
    padding-left: 20px;
  }
  .lggScreen{
    display: none;
  }
  .xsMargin{
    margin-top: -4rem !important;
    font-size: 28px !important;
  }
  .xsCards{
    width: 100% !important;
  }
  .xsImage{
    height: 80% !important;
  }

  .xsMarginTop{
    margin-top: 4rem !important;
  }
  .xsMarginB{
    margin-bottom: 100px !important;
  }
  .dialogImage1{
    height: auto !important;
  }
  .boxx{
    padding: 0px;
  }
  .justifyModify{
    justify-content: center !important;
  }
  .borde-footer {
    border-bottom: 1px solid;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

}
@media screen and (max-width:500px){
  .fullWidthCard{
    width: 100% !important;
  }
  .letra{
    font-size: 70%;
  }

}
.a{
  text-decoration: none;
  color:white
}

* {
  margin: 0;
  padding: 0;
  
}

.cardLeyenda{
    box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
    background-color: rgba(3, 85, 146, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    text-align: center;
}

.tit{
    margin-top: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 45px;
}

.divTit{
    text-align: center;
    color: white;
}


 
.parrafo{
    font-size: 20px;
     
}


@media screen and (max-width:428px) {
    .tit{
        font-size:22px ;

    }
    .parrafo{
        font-size: 12px;
    }
    
}

@media screen and (max-width: 576px){
    .tit{
        font-size: 30px;
    }
    .parrafo{
        font-size: 15px;
    }
}

.Iconos_iconos__2O-9f{
    display: flex;
    justify-content: center;
    font-size: 35px;
  
  
    
    
    
}
